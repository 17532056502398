import React, {Component} from 'react';


class Detail extends Component{

  render() {
    return(
    <div>
      <div className="blurFilter"  onClick={this.props.setInfoStateFalse}/>
      <div className="DetailPage">
        <div className="detailContainer">
          <h2 className="infoHeadblock">Information</h2>
          <div className="infoMainblock1">
            <h3 className="infoHeadline">Impressum & Kontakt</h3>
            <article class ="BeschreibungNaturwein">
              <p className="infoText">
                Konstantin G., Per E., Paul Felix K.<br/>
                Treskowallee 8, 10566 Berlin<br/>
                Telefon: +49 30 8813011<br/>
                Fax: +49 30 115439<br/>
                Email: <a href="mailto:kontakt.web-tech@groll.net">kontakt.web-tech@groll.net</a><br/>
                Internet: <a href="https://web-tech.groll.net">www.web-tech.groll.net</a><br/>
              </p>
            </article>
          </div>

          <div className="infoMainblock2">
            <h3 className="infoHeadline">Direktkontakt</h3>
            <textarea className="TAinfo" id="winedescription" type="text" placeholder="Btte fügen Sie hier Ihre Anfrage ein..."/>
            <button className="ABSENDENinfo" onClick={function(){
              var mailbody = document.getElementById("winedescription");
              var mailto_link = 'mailto:kontakt.web-tech@groll.net?subject=TeamWeinfinder Anfrage&body=' + mailbody.value;
              var win = window.open(mailto_link, 'emailWindow');
              setTimeout(function(){
                win.close()
              }, 1)}
            }>Absenden</button>
          </div>

          <div class = "infoMainblock3">
            <h3 className="infoHeadline">Über Uns</h3>
            <article class ="BeschreibungNaturwein">
              <p className="infoText">
                Wir sind ein junges Team, dass sich dem Wein verschrieben hat. Mit dem Weinfinder haben die Gründer Paul Felix K., Konstantin G. und Per E. eine Bereicherung für jeden Naturweinliebhaber geschaffen. Mit der Web basierten App kann jeder Weine finden, die perfekt zum jeweiligen Geschmack passen. Händler & Winzer können auf der Weinfinder.de-Plattform ihre neuesten Kreationen und all Time classics anbieten. In 2019 gegründet und mit Fertigstellung der Anwendung im selben Jahr lässt das Team für die Zukunft noch einiges erwarten.
              </p>
            </article>
          </div>
          <div className="infoBottomblock">
            <button className="detailButton" onClick={this.props.setInfoStateFalse}>Schließen</button>
          </div>
        </div>
      </div>
    </div>
    )
  }
  };



export default Detail;
