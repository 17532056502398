import React, {Component} from 'react';



class Detail_addWine extends Component{
  constructor(props){
    super(props)
    this.state = {
      file: null,

      picState: "",
    }

    this.manageInsert = this.manageInsert.bind(this)
    this.regulateWinePic = this.regulateWinePic.bind(this)
    this.handleArtChange = this.handleArtChange.bind(this)
  }

  fillOptions() {
    fetch('php/getTags.php',)
    .then(response => response.json())
    .then(result => {
      var tagChoice = document.getElementById("tagChoice");
      var option = document.createElement("OPTION");

      for (var i = 0; i < result.length; i++) {
        option = document.createElement("OPTION");
        option.innerHTML = result[i].tag;
        option.value = result[i].tag;
        option.label = result[i].tag;
        tagChoice.options.add(option);
      }
    });
    }



  manageInsert() {
    fetch('php/getTags.php',)
    .then(response => response.json())
    .then(result => {
      var tagsArray = result;
      var tagIdToDeliver;

      var tagFromChoicefield = document.getElementById("tagChoice");
      var tagFromTextfield = document.getElementById("tagText");

      tagsArray.forEach(function(tag) {
        if(tag['tag'] === tagFromChoicefield.options[tagFromChoicefield.selectedIndex].value){
          tagIdToDeliver = tag['tag_id'];
        }
      })

      var valueToDeliver;

      if(tagFromTextfield.value !== ''){
        valueToDeliver = {tag_id: null, tag: tagFromTextfield.value.trim()};
      } else {
        valueToDeliver = {tag_id: tagIdToDeliver, tag: tagFromChoicefield.options[tagFromChoicefield.selectedIndex].value};
      }

      var art = document.getElementById("art");
      var rebsorte = document.getElementById("rebsorte");
      var land = document.getElementById("land");
      var region = document.getElementById("region");
      var jahrgang = document.getElementById("jahrgang");
      var intensität = document.getElementById("intensität");
      var süßegrad = document.getElementById("süßegrad");

      var newWine = {
        winename: document.getElementById("name").value.trim(),
        winedescription: document.getElementById("winedescription").value.trim(),
        wineimage:"test",
        art: art.options[art.selectedIndex].value,
        rebsorte: rebsorte.options[rebsorte.selectedIndex].value,
        land: land.options[land.selectedIndex].value,
        region: region.options[region.selectedIndex].value,
        jahrgang: jahrgang.options[jahrgang.selectedIndex].value,
        intensität: intensität.options[intensität.selectedIndex].value,
        süßegrad: süßegrad.options[süßegrad.selectedIndex].value,
        tag_id : valueToDeliver.tag_id,
        tag : valueToDeliver.tag
      }

        fetch('php/insertWine.php', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            newWine
          )
        })
        this.props.setAddStateFalse();
        this.props.internalReload();
    });
  }


  regulateWinePic(){
    if(this.state.picState === "rot"){
      return "img/rot.png";
    } else if(this.state.picState === "rosé") {
        return "img/rose.png";
    } else if(this.state.picState === "weiss") {
        return "img/weiss.png";
    } else {
        return "";
    }
  }

  handleArtChange(event) {
    const value = event.target.value;
    this.setState({
      picState: value,
    });
}


  render() {
    return(
    <div>
      <div className="blurFilter"  onClick={this.props.setAddStateFalse}/>
      <div className="DetailPage">
        <div className="detailContainer">
          <h2 className="wineHeadlineDetail">Wein hinzufügen</h2>

          <div className="winePicContainerDetail" id="editPic">
            <img className="previewImage_Detail_addWine" alt="Bitte wählen Sie für die Bildanzeige  rechts eine Weinart aus." src={this.regulateWinePic()}/>
          </div>

          <div className="categoryContainer_Detail_addWine">
            <div className="filter_Detail_addWine">
              <p className="labelFilter">Name</p>
              <input id="name" type="text" className="auswahlFilter" maxlength="50"/>
            </div>
            <div className="filter_Detail_addWine">
              <p className="labelFilter">Art</p>
              <select id="art" className="auswahlFilter" size="1" onKeyDown="true" onChange={this.handleArtChange}>
                <option value="rot">rot</option>
                <option value="weiss">weiss</option>
                <option value="rosé">rosé</option>
              </select>
            </div>
            <div className = "filter_Detail_addWine">
              <p className="labelFilter">Rebsorte</p>
              <select id="rebsorte" className="auswahlFilter" size="1" onkeydown="true">
                <option value="Chardonnay">Chardonnay</option>
                <option value="Chianti">Chianti</option>
                <option value="Dornfelder">Dornfelder</option>
                <option value="Grauburgunder">Grauburgunder</option>
                <option value="Müller-Thurgau">Müller-Thurgau</option>
                <option value="Riesling">Riesling</option>
                <option value="Sauvignon">Sauvignon</option>
                <option value="Sancerré">Sancerré</option>
                <option value="Shiraz">Shiraz</option>
                <option value="Trollinger">Trollinger</option>
              </select>
            </div>
            <div className = "filter_Detail_addWine">
              <p className="labelFilter">Land</p>
              <select id="land" className="auswahlFilter" size="1" onkeydown="true">
                <option value="Deutschland">Deutschland</option>
                <option value="Frankreich">Frankreich</option>
                <option value="Italien">Italien</option>
                <option value="Portugal">Portugal</option>
                <option value="Spanien">Spanien</option>
              </select>
            </div>
            <div className = "filter_Detail_addWine">
              <p className="labelFilter">Region</p>
              <select id="region" className="auswahlFilter" size="1" onkeydown="true">
                <option value="Bordeaux">Bordeaux</option>
                <option value="Bretagne">Bretagne</option>
                <option value="Burgund">Burgund</option>
                <option value="Mosel">Mosel</option>
                <option value="Rioja">Rioja</option>
                <option value="Toskana">Toskana</option>
              </select>
            </div>
            <div className = "filter_Detail_addWine">
              <p className="labelFilter">Jahrgang</p>
              <select id="jahrgang" className="auswahlFilter" size="1" onkeydown="true">
                <option value="1970">1970</option>
                <option value="1971">1971</option>
                <option value="1972">1972</option>
                <option value="1973">1973</option>
                <option value="1974">1974</option>
                <option value="1975">1975</option>
                <option value="1976">1976</option>
                <option value="1977">1977</option>
                <option value="1978">1978</option>
                <option value="1979">1979</option>
                <option value="1980">1980</option>
                <option value="1981">1981</option>
                <option value="1982">1982</option>
                <option value="1983">1983</option>
                <option value="1984">1984</option>
                <option value="1985">1985</option>
                <option value="1986">1986</option>
                <option value="1987">1987</option>
                <option value="1988">1988</option>
                <option value="1989">1989</option>
                <option value="1990">1990</option>
                <option value="1991">1991</option>
                <option value="1992">1992</option>
                <option value="1993">1993</option>
                <option value="1994">1994</option>
                <option value="1995">1995</option>
                <option value="1996">1996</option>
                <option value="1997">1997</option>
                <option value="1998">1998</option>
                <option value="1999">1999</option>
                <option value="2000">2000</option>
                <option value="2001">2001</option>
                <option value="2002">2002</option>
                <option value="2003">2003</option>
                <option value="2004">2004</option>
                <option value="2005">2005</option>
                <option value="2006">2006</option>
                <option value="2007">2007</option>
                <option value="2008">2008</option>
                <option value="2009">2009</option>
                <option value="2010">2010</option>
                <option value="2011">2011</option>
                <option value="2012">2012</option>
                <option value="2013">2013</option>
                <option value="2014">2014</option>
                <option value="2015">2015</option>
                <option value="2016">2016</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
              </select>
            </div>
            <div className = "filter_Detail_addWine">
              <p className="labelFilter">Intensität</p>
              <select id="intensität" className="auswahlFilter" size="1" onkeydown="true">
                <option value="dünn">dünn</option>
                <option value="expressiv">expressiv</option>
                <option value="fett">fett</option>
                <option value="intensiv">intensiv</option>
                <option value="kräftig">kräftig</option>
                <option value="schwer">schwer</option>
                <option value="verhalten">verhalten</option>
                <option value="voluminös">voluminös</option>
              </select>
            </div>
            <div className = "filter_Detail_addWine">
              <p className="labelFilter">Süßegrad</p>
              <select id="süßegrad" className="auswahlFilter" size="1" onkeydown="true">
                <option value="dezent">dezent</option>
                <option value="feinherb">feinherb</option>
                <option value="halbtrocken">halbtrocken</option>
                <option value="herb">herb</option>
                <option value="lieblich">lieblich</option>
                <option value="süß">süß</option>
                <option value="trocken">trocken</option>
              </select>
            </div>

            <div className="filter_Detail_addWine">
              <p className="labelFilter">Tag</p>
              <select id="tagChoice" className="auswahlFilter" size="1" onKeyDown="true">
                {this.fillOptions()}
              </select>
            </div>
            <div className="filter_Detail_addWine">
              <p className="labelFilter">Tag hinzufügen:</p>
              <input id="tagText" type="text" className="auswahlFilter" maxlength="30"/>
            </div>
          </div>
          <textarea id="winedescription" className="wineDescriptionDetail" type="text" placeholder="Btte fügen Sie hier eine Beschreibung des Weins ein..." maxlength="255"/>
          <div className="buttonContainerDetail">
            <button className="detailButton" onClick={this.props.setAddStateFalse}>Abbrechen</button>
            <button className="detailButton" onClick={this.manageInsert}>Speichern</button>
          </div>
        </div>
      </div>
    </div>
    )
  };
}


export default Detail_addWine;
