import React, {Component} from 'react';


class Detail extends Component{

  render() {
    return(
    <div>
      <div className="blurFilter"  onClick={this.props.setShowDetailFalse}/>
      <div className="DetailPage">
        <div className="detailContainer">
          <h2 className="wineHeadlineDetail">{this.props.wine.winename}</h2>
          <div className="winePicContainerDetail">
            <img className="weinflascheDetail" src={this.props.wine.wineimage} alt="weinflasche"/>
          </div>
          <p className="categoryContainer_Detail">
            <p className="categoryTag">{this.props.wine.art}</p>
            <p className="categoryTag">{this.props.wine.rebsorte}</p>
            <p className="categoryTag">{this.props.wine.süßegrad}</p>
            <p className="categoryTag">{this.props.wine.intensität}</p>
            <p className="categoryTag">{this.props.wine.land}</p>
            <p className="categoryTag">{this.props.wine.jahrgang}</p>
            <p className="categoryTag">{this.props.wine.region}</p>
            <p className="categoryTagDynamic">{this.props.wine.tag}</p>
          </p>
          <div class = "googleMapsMap_Container_Details">
            <iframe title="gMap"  class = "googleMapsMap_Details" src={this.props.wine.link}></iframe>
          </div>
          <p className="wineDescriptionDetail">{this.props.wine.winedescription}</p>
          <div className="buttonContainerDetail">
            <button className="detailButton" onClick={this.props.deleteWine}>Löschen</button>
            <button className="detailButton" onClick={this.props.setShowDetailFalseAndSetShowEditTrue}>Bearbeiten</button>
            <button className="detailButton" onClick={this.props.setShowDetailFalse}>Abbrechen</button>
          </div>
        </div>
      </div>

    </div>
    )
  };
}


export default Detail;
