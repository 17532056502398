import React, { Component } from 'react';
import './App.css';


import Winelist from './Winelist.js';
import DetailAddWine from './DetailAddWine.js'
import Info from './Info.js'





class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      wines: [],
      name: "TeamWeinfinder",
      add_state: false,
      filters: {},
      maxJahr: 2020,
      minJahr: 1970,
      suchwert: '',

      show_Info_state: false,
    };
    this.setAddStateTrue = this.setAddStateTrue.bind(this);
    this.setAddStateFalse = this.setAddStateFalse.bind(this);
    this.internalReload = this.internalReload.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.setMinJahr = this.setMinJahr.bind(this);
    this.setMaxJahr = this.setMaxJahr.bind(this);
    this.fillOptions = this.fillOptions.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.resetFilterManager = this.resetFilterManager.bind(this);

    this.setInfoStateFalse = this.setInfoStateFalse.bind(this);
    this.setInfoStateTrue = this.setInfoStateTrue.bind(this);
    this.showInfo = this.showInfo.bind(this);
  }




  loadWines() {
    fetch("/php/getWine.php")
    .then(response => response.json())
    .then(result => {
      this.setState({
        isLoaded: true,
        wines: result,
      });
    },
    (error) => {
      this.setState({
        isLoaded: true,
        error
      });
    }
  )}

  fillOptions() {
    fetch('php/getTags.php',)
    .then(response => response.json())
    .then(result => {
      var tagChoice = document.getElementById("tagChoiceMain");
      for (var i = 0; i < result.length; i++) {
        var option = document.createElement("OPTION");
        option.innerHTML = result[i].tag;
        option.value = result[i].tag;
        option.label = result[i].tag;
        tagChoice.options.add(option);
      }
      });
    }



  winesLoaded(data) {
    console.log("timeout fired");
    this.setState({wines: data.wines, selectedWines: data.wines})
  }

  componentDidMount() {
    this.loadWines();
    this.fillOptions()
  }


  internalReload = () => {
    setTimeout(() => {
    this.setState({isLoaded: false})
    this.loadWines();

}, 2000);
    }




  /*
      Ab hier: Filterfunktion
  */
//Überwacht Eingabe von Filtern
  handleFilterChange(event) {
    const { name, value } = event.target;
    const filters = this.state.filters;

    if(value === 'Alle'){
      delete filters[name];
    } else {
      filters[name] = value;
    }
    this.setState({filters});
}

//Überwacht Eingabe in Suchfeld
  handleSearchChange(event) {
    const searchValue = event.target.value;
    if(searchValue !== ''){
      this.setState({suchwert: searchValue})
    } else {
      this.setState({suchwert: ''})
    }
  }

 setMinJahr(event){
   this.setState({minJahr: event.target.value === 'Alle' ? 1970 : +event.target.value});
 }
 setMaxJahr(event){
   this.setState({maxJahr: event.target.value === 'Alle' ? 2020 : +event.target.value});
 }

  filterWines(wines, filters) {
    //Anwenden der Choicefield-Filter (außer Jahrgangs-Filter)
    const selectedWines = Object.keys(filters)
      .reduce((result, key) => result.filter(wine => wine[key] === filters[key]), wines);

    //Anwenden des Suchwort-Filters
    if(this.state.suchwert !== ''){
      //Suchwort-Filter wird angewendet auf Namen des Weins --> Alle Weine, die Suchwort im Namen haben
      const selectedWinesFilteredByName = selectedWines.filter(wine => wine.winename.toLowerCase().replace(/\s+/g, '').includes(this.state.suchwert.toLowerCase().replace(/\s+/g, '')));
      //Suchwort-Filter wird angewendet auf Beschreibung des Weins --> Alle Weine, die Suchwort in Beschreibung haben
      const selectedWinesFilteredByDescription = selectedWines.filter(wine => wine.winedescription.toLowerCase().replace(/\s+/g, '').includes(this.state.suchwert.toLowerCase().replace(/\s+/g, '')));
      //Alle Ergebnisweine zusammenfügen (Duplikate möglich, wenn Suchbegriff in Namen und Beschreibung ist)
      const remainingWines = selectedWinesFilteredByName.concat(selectedWinesFilteredByDescription);
      //Duplikate herausfiltern
      const uniqueWines = remainingWines.filter(function(item, pos, self) {
          return self.indexOf(item) === pos;
      })
      //Anwenden der Jahrgangs-Filter nach Suchwortfilterung
      return uniqueWines.filter(wine => +wine.jahrgang >= this.state.minJahr && +wine.jahrgang <= this.state.maxJahr)
    }

    //Anwenden der Jahrgangs-Filter ohne Suchwortfilterung
    return selectedWines.filter(wine => +wine.jahrgang >= this.state.minJahr && +wine.jahrgang <= this.state.maxJahr)
}
/*
    Bis  hier: Filterfunktion
*/







/*
  Ab hier: Öffnen/Schließen des "Wein-hinzufügen" Fensters
*/
  show_DetailAddWine(){
    if (this.state.add_state === true){
      return(<DetailAddWine wine={this.props.wine} setAddStateFalse={this.setAddStateFalse} internalReload={this.internalReload}/>)
      }
  }
  setAddStateTrue(){
    this.setState({
      add_state: true
    })
  }
  setAddStateFalse(){
    this.setState({
      add_state: false
    })
  }
  /*
    Bis hier: Öffnen/Schließen des "Wein-hinzufügen" Fensters
  */


//Setzt Values aller Auswahlfilter und des Suchfeldes auf null (Ausgangswert) zurück und
//ändert anschließend die States, um einen Reload zu erzeugen.
  resetFilterManager(){
    document.getElementById("artFilter").value = "Alle";
    document.getElementById("rebsorteFilter").value = "Alle";
    document.getElementById("landFilter").value = "Alle";
    document.getElementById("regionFilter").value = "Alle";
    document.getElementById("jahrgangAbFilter").value = "Alle";
    document.getElementById("jahrgangBisFilter").value = "Alle";
    document.getElementById("intensitätFilter").value = "Alle";
    document.getElementById("süßegradFilter").value = "Alle";
    document.getElementById("tagChoiceMain").value = "Alle";
    document.getElementById("suchFilter").value = '';
    this.setState({
      filters: {},
      minJahr: 1970,
      maxJahr: 2020,
      suchwert: '',
    })
  }


  /*
    Ab hier: Öffnen/Schließen des "Information" Fensters
  */
  showInfo(){
    if (this.state.show_Info_state === true){
      return(<Info setInfoStateFalse={this.setInfoStateFalse}/>)
      }
  }
  setInfoStateTrue(){
    this.setState({
      show_Info_state: true
    })
  }

  setInfoStateFalse(){
    this.setState({
      show_Info_state: false
    })
  }
  /*
    Ab hier: Öffnen/Schließen des "Information" Fensters
  */





  render() {
    const selectedWines = this.filterWines(this.state.wines, this.state.filters);
  return (
    <div className="App">
    <div className="container">


<header className="header">
    <h1 className="websiteName"><a className="websiteNameAsLink" href="about.html">{this.state.name}</a></h1>
    <form className="searchHeader" onChange={this.handleSearchChange}>
      <input id="suchFilter" className="suchenEingabefeldHeader" placeholder="Search.." name="search"></input>
    </form>
</header>


<div className="spaceLeft"></div>

<div className="content_Startseite">

  <div className = "filterblock">
    <div className="filter">
      <p className="labelFilter">Art</p>
      <select id="artFilter" className="auswahlFilter" name="art" size="1" onKeyDown="true" onChange={this.handleFilterChange}>
        <option value="Alle">Alle</option>
        <option value="rot">rot</option>
        <option value="weiss">weiss</option>
        <option value="rosé">rosé</option>
      </select>
    </div>
    <div className = "filter">
      <p className="labelFilter">Rebsorte</p>
      <select id="rebsorteFilter" className="auswahlFilter" size="1" name="rebsorte" onkeydown="true" onChange={this.handleFilterChange}>
        <option value="Alle">Alle</option>
        <option value="Chardonnay">Chardonnay</option>
        <option value="Chianti">Chianti</option>
        <option value="Dornfelder">Dornfelder</option>
        <option value="Grauburgunder">Grauburgunder</option>
        <option value="Müller-Thurgau">Müller-Thurgau</option>
        <option value="Riesling">Riesling</option>
        <option value="Sauvignon">Sauvignon</option>
        <option value="Sancerré">Sancerré</option>
        <option value="Shiraz">Shiraz</option>
        <option value="Trollinger">Trollinger</option>
      </select>
    </div>
    <div className = "filter">
      <p className="labelFilter">Land</p>
      <select id="landFilter" className="auswahlFilter" size="1" name="land" onkeydown="true" onChange={this.handleFilterChange}>
        <option value="Alle">Alle</option>
        <option value="Deutschland">Deutschland</option>
        <option value="Frankreich">Frankreich</option>
        <option value="Italien">Italien</option>
        <option value="Portugal">Portugal</option>
        <option value="Spanien">Spanien</option>
      </select>
    </div>
    <div className = "filter">
      <p className="labelFilter">Region</p>
      <select id="regionFilter" className="auswahlFilter" size="1" name="region" onkeydown="true" onChange={this.handleFilterChange}>
        <option value="Alle">Alle</option>
        <option value="Bordeaux">Bordeaux</option>
        <option value="Bretagne">Bretagne</option>
        <option value="Burgund">Burgund</option>
        <option value="Mosel">Mosel</option>
        <option value="Rioja">Rioja</option>
        <option value="Toskana">Toskana</option>
      </select>
    </div>
    <div className = "filter">
      <p className="labelFilter">Jahrgang ab</p>
      <select id="jahrgangAbFilter" className="auswahlFilter" size="1" name="minJahr" onChange={this.setMinJahr} onkeydown="true">
        <option value="Alle">Alle</option>
        <option value="1970">1970</option>
        <option value="1971">1971</option>
        <option value="1972">1972</option>
        <option value="1973">1973</option>
        <option value="1974">1974</option>
        <option value="1975">1975</option>
        <option value="1976">1976</option>
        <option value="1977">1977</option>
        <option value="1978">1978</option>
        <option value="1979">1979</option>
        <option value="1980">1980</option>
        <option value="1981">1981</option>
        <option value="1982">1982</option>
        <option value="1983">1983</option>
        <option value="1984">1984</option>
        <option value="1985">1985</option>
        <option value="1986">1986</option>
        <option value="1987">1987</option>
        <option value="1988">1988</option>
        <option value="1989">1989</option>
        <option value="1990">1990</option>
        <option value="1991">1991</option>
        <option value="1992">1992</option>
        <option value="1993">1993</option>
        <option value="1994">1994</option>
        <option value="1995">1995</option>
        <option value="1996">1996</option>
        <option value="1997">1997</option>
        <option value="1998">1998</option>
        <option value="1999">1999</option>
        <option value="2000">2000</option>
        <option value="2001">2001</option>
        <option value="2002">2002</option>
        <option value="2003">2003</option>
        <option value="2004">2004</option>
        <option value="2005">2005</option>
        <option value="2006">2006</option>
        <option value="2007">2007</option>
        <option value="2008">2008</option>
        <option value="2009">2009</option>
        <option value="2010">2010</option>
        <option value="2011">2011</option>
        <option value="2012">2012</option>
        <option value="2013">2013</option>
        <option value="2014">2014</option>
        <option value="2015">2015</option>
        <option value="2016">2016</option>
        <option value="2017">2017</option>
        <option value="2018">2018</option>
        <option value="2019">2019</option>
        <option value="2020">2020</option>
      </select>
    </div>
    <div className = "filter">
      <p className="labelFilter">Jahrgang bis</p>
      <select id="jahrgangBisFilter" className="auswahlFilter" size="1" name="maxJahr" onChange={this.setMaxJahr} onkeydown="true">
        <option value="Alle">Alle</option>
        <option value="1970">1970</option>
        <option value="1971">1971</option>
        <option value="1972">1972</option>
        <option value="1973">1973</option>
        <option value="1974">1974</option>
        <option value="1975">1975</option>
        <option value="1976">1976</option>
        <option value="1977">1977</option>
        <option value="1978">1978</option>
        <option value="1979">1979</option>
        <option value="1980">1980</option>
        <option value="1981">1981</option>
        <option value="1982">1982</option>
        <option value="1983">1983</option>
        <option value="1984">1984</option>
        <option value="1985">1985</option>
        <option value="1986">1986</option>
        <option value="1987">1987</option>
        <option value="1988">1988</option>
        <option value="1989">1989</option>
        <option value="1990">1990</option>
        <option value="1991">1991</option>
        <option value="1992">1992</option>
        <option value="1993">1993</option>
        <option value="1994">1994</option>
        <option value="1995">1995</option>
        <option value="1996">1996</option>
        <option value="1997">1997</option>
        <option value="1998">1998</option>
        <option value="1999">1999</option>
        <option value="2000">2000</option>
        <option value="2001">2001</option>
        <option value="2002">2002</option>
        <option value="2003">2003</option>
        <option value="2004">2004</option>
        <option value="2005">2005</option>
        <option value="2006">2006</option>
        <option value="2007">2007</option>
        <option value="2008">2008</option>
        <option value="2009">2009</option>
        <option value="2010">2010</option>
        <option value="2011">2011</option>
        <option value="2012">2012</option>
        <option value="2013">2013</option>
        <option value="2014">2014</option>
        <option value="2015">2015</option>
        <option value="2016">2016</option>
        <option value="2017">2017</option>
        <option value="2018">2018</option>
        <option value="2019">2019</option>
        <option value="2020">2020</option>
      </select>
    </div>
    <div className = "filter">
      <p className="labelFilter">Intensität</p>
      <select id="intensitätFilter" className="auswahlFilter" size="1" name="intensität" onkeydown="true" onChange={this.handleFilterChange}>
        <option value="Alle">Alle</option>
        <option value="dünn">dünn</option>
        <option value="expressiv">expressiv</option>
        <option value="fett">fett</option>
        <option value="intensiv">intensiv</option>
        <option value="kräftig">kräftig</option>
        <option value="schwer">schwer</option>
        <option value="verhalten">verhalten</option>
        <option value="voluminös">voluminös</option>
      </select>
    </div>
    <div className = "filter">
      <p className="labelFilter">Süßegrad</p>
      <select id="süßegradFilter" className="auswahlFilter" size="1" name="süßegrad" onkeydown="true" onChange={this.handleFilterChange}>
        <option value="Alle">Alle</option>
        <option value="dezent">dezent</option>
        <option value="feinherb">feinherb</option>
        <option value="halbtrocken">halbtrocken</option>
        <option value="herb">herb</option>
        <option value="lieblich">lieblich</option>
        <option value="süß">süß</option>
        <option value="trocken">trocken</option>
      </select>
    </div>
    <div className = "filter">
      <p className="labelFilter">Tag</p>
      <select className="auswahlFilter" size="1" name="tag" onkeydown="true" id="tagChoiceMain" onChange={this.handleFilterChange}>
        <option value="Alle">Alle</option>
      </select>
    </div>
  </div>

  <div className = "filterbuttonBlock">
    <button className="filterButton" onClick={this.resetFilterManager}>Filter zurücksetzen</button>
  </div>

  <button className="addButton" onClick={this.setAddStateTrue}>
   <i class="fa fa-plus"></i>
  </button>

 <Winelist wines={selectedWines} internalReload={this.internalReload}/>

 {this.show_DetailAddWine()}

</div>

<div className="spaceRight"></div>

<footer className="footer">
     <div className = "impressumFooterContainer">
      <p className= "impressumFooter">© TeamWeinfinder 2019</p>
     </div>
     <div className="linksFooterContainer">
      <li className="linkFooter" onClick={this.setInfoStateTrue}>Impressum</li>
      <li className="linkFooter" onClick={this.setInfoStateTrue}>Kontakt</li>
      <li className="linkFooter" onClick={this.setInfoStateTrue}>Über uns</li>
     </div>
     {this.showInfo()}
</footer>
    </div>
</div>
  );
}
}

export default App;
