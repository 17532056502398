import React, {Component} from 'react';
import Tile from './Tile.js';



class Winelist extends Component{
  render() {
    const tiles = this.props.wines && this.props.wines.length > 0 ? this.props.wines.map(function(elem){
      return <Tile wine={elem}/>
    }) : <img src="spinner.gif" alt="loading" />;

    return <div className="tileBlock">{tiles}</div>
  };
}


export default Winelist;
