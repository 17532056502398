import React, {Component} from 'react';
import Detail from './Detail.js';
import DetailEditWine from './DetailEditWine.js'



class Tile extends Component{

  constructor(props){
    super(props);
    this.state = {
      show_detail_state: false,
      show_edit_state: false,
    };
    this.setShowDetailTrue = this.setShowDetailTrue.bind(this);
    this.setShowDetailFalse = this.setShowDetailFalse.bind(this);
    this.deleteWine = this.deleteWine.bind(this);
    this.setShowEditTrue = this.setShowEditTrue.bind(this);
    this.setShowEditFalse = this.setShowEditFalse.bind(this);
    this.setShowDetailFalseAndSetShowEditTrue = this.setShowDetailFalseAndSetShowEditTrue.bind(this);
  }


// "State"-Managers
  setShowDetailTrue(){
    this.setState({
      show_detail_state: true
    })
  }
  setShowDetailFalse(){
    this.setState({
      show_detail_state: false
    })
  }
  setShowEditTrue(){
    this.setState({
      show_edit_state: true
    })
  }
  setShowEditFalse(){
    this.setState({
      show_edit_state: false
    })
  }
  setShowDetailFalseAndSetShowEditTrue(){
    this.setState({
      show_detail_state: false,
      show_edit_state: true,
    })
  }



  deleteWine(){
    fetch('php/deleteWine.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        this.props.wine
      )
    })
    this.setShowDetailFalse();
  }






  render(){
    if (this.state.show_detail_state === false && this.state.show_edit_state === false){
    return(
        <div className = "tile" onClick={this.setShowDetailTrue}>
          <div className="weinflascheContainer">
            <img className="weinflasche" alt="Weinflasche" src={this.props.wine.wineimage}/>
          </div>
          <div className="weinBeschreibungContainer">
            <h3 className="weinBeschreibungHeadline">{this.props.wine.winename}</h3>
            <p className="categoryContainer"><p className="categoryTag">{this.props.wine.art}</p><p className="categoryTag">{this.props.wine.rebsorte}</p><p className="categoryTag">{this.props.wine.süßegrad}</p><p className="categoryTag">{this.props.wine.intensität}</p><p className="categoryTag">{this.props.wine.land}</p><p className="categoryTag">{this.props.wine.jahrgang}</p><p className="categoryTag">{this.props.wine.region}</p><p className="categoryTagDynamic">{this.props.wine.tag}</p></p>
          </div>
        </div>
      );
    } else if (this.state.show_detail_state === true) {
        return(
            <div className = "tile">
              <div className="weinflascheContainer">
                <img className="weinflasche" alt="Weinflasche" src={this.props.wine.wineimage}/>
              </div>
              <div className="weinBeschreibungContainer">
                <h3 className="weinBeschreibungHeadline"><a href="detail.html">{this.props.wine.winename}</a></h3>
                <p className="categoryContainer"><p className="categoryTag">{this.props.wine.art}</p><p className="categoryTag">{this.props.wine.rebsorte}</p><p className="categoryTag">{this.props.wine.süßegrad}</p><p className="categoryTag">{this.props.wine.intensität}</p><p className="categoryTag">{this.props.wine.land}</p><p className="categoryTag">{this.props.wine.jahrgang}</p><p className="categoryTag">{this.props.wine.region}</p></p>
              </div>
              <Detail wine={this.props.wine} setShowDetailFalseAndSetShowEditTrue={this.setShowDetailFalseAndSetShowEditTrue} setShowDetailFalse={this.setShowDetailFalse} deleteWine={this.deleteWine}/>
            </div>
          );
    } else if (this.state.show_edit_state === true){
        return(
            <div className = "tile">
              <div className="weinflascheContainer">
                <img className="weinflasche" alt="Weinflasche" src={this.props.wine.wineimage}/>
              </div>
              <div className="weinBeschreibungContainer">
                <h3 className="weinBeschreibungHeadline"><a href="detail.html">{this.props.wine.winename}</a></h3>
                <p className="categoryContainer"><p className="categoryTag">{this.props.wine.art}</p><p className="categoryTag">{this.props.wine.rebsorte}</p><p className="categoryTag">{this.props.wine.süßegrad}</p><p className="categoryTag">{this.props.wine.intensität}</p><p className="categoryTag">{this.props.wine.land}</p><p className="categoryTag">{this.props.wine.jahrgang}</p><p className="categoryTag">{this.props.wine.region}</p></p>
              </div>
              <DetailEditWine wine={this.props.wine} setShowEditFalse={this.setShowEditFalse}/>
            </div>
          );
    }
  }

}

export default Tile;
